import request from './request'

function getActivityBanners(data) {
    return request('get','/qxapi/edu/activitybanner/activitybanners', data)
}

function list(data) {
    return request('get','/qxapi/edu/activitysetting/page', data)
}

function getById(data) {
    return request('get','/qxapi/edu/activitysetting/get_details/' + data)
}

function getActivitys(data) {
    return request('get','/qxapi/edu/activity/activitys', data)
}

function getActivityById(data) {
    return request('get','/qxapi/edu/activity/activitys/'+ data)
}

function getActivityDetails(data) {
    return request('get','/qxapi/edu/activity/activitydetails/'+ data)
}

function buyActivity(data) {
    return request("post", "/qxapi/edu/activity/buyactivity", data)
}

function buyVolunteerActivity(data) {
    return request("post", "/qxapi/edu/activity/buyvolunteeractivity", data)
}

function checkActivityAage(data) {
    return request("get", "/qxapi/edu/activity/checkactivityage", data)
}

function checkField(data) {
    return request("post", "/qxapi/edu/activity/checkfield", data)
}

function getActivityBuysRptByCust(data) {
    return request("get", "/qxapi/edu/activity/activitybuysrptbycust", data)
}

function getActivityBuyDetails(data) {
    return request("get", "/qxapi/edu/activity/getactivitybuydetails", data)
}

function getActivityBuysRptById(data) {
    return request("get", "/qxapi/edu/activity/activitybuysrptbyId", data)
}

function getLearningWorlds(data) {
    return request("get", "/qxapi/edu/learningworld/learningworlds", data)
}

function getLearningWorldsById(data) {
    return request("get", "/qxapi/edu/learningworld/learningworlds/" + data)
}

function addLearningWorldViews(data) {
    return request("get", "/qxapi/edu/learningworld/addlearningworldviews", data)
}

function submitComment(data) {
    return request("post", "/qxapi/edu/activity/submitcomment", data)
}

function cancelBuyActivity(data) {
    return request("get", "/qxapi/edu/activity/cancelbuyactivity", data)
}

function getVolunteerCommentRptById(data) {
    return request("get", "/qxapi/edu/activityvolunteer/activityvolunteers/"+ data)
}

function submitVolunteerComment(data) {
    return request("post", "/qxapi/edu/activityvolunteer/activityvolunteers", data)
}

function getActivityVolunteersForPage(data) {
    return request("get", "/qxapi/edu/activityvolunteer/activityvolunteersforpage", data)
}

function deleteActivityVolunteers(data) {
    return request("delete", "/qxapi/edu/activityvolunteer/activityvolunteers", data)
}

function getVolunteerTeams(data) {
    return request("get", "/qxapi/edu/volunteerteams/listforparent", data)
}

function getVolunteerTeamChilds(data) {
    return request("get", "/qxapi/edu/volunteerteams/teamchilds", data)
}

function getActivityBuyMasters(data) {
    return request("get", "/qxapi/edu/activity/getactivitybuymasters", data)
}

function getActivitySettingTypes(data) {
    return request("get", "/qxapi/edu/activity/activitysettingtypes", data)
}

export default {
    list,
	getById,
    getActivitys,
    getActivityById,
    getActivityDetails,
    buyActivity,
    getActivityBanners,
    checkActivityAage,
    checkField,
    buyVolunteerActivity,
    getActivityBuysRptByCust,
    getActivityBuysRptById,
    getLearningWorlds,
    getLearningWorldsById,
    addLearningWorldViews,
    submitComment,
    cancelBuyActivity,
    getVolunteerCommentRptById,
    submitVolunteerComment,
    getActivityVolunteersForPage,
    deleteActivityVolunteers,
    getVolunteerTeams,
    getVolunteerTeamChilds,
    getActivityBuyMasters,
    getActivityBuyDetails,
    getActivitySettingTypes
}
