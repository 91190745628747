<!--20240529_1-->
<template>
  <div class="edu-frame">
    <edu-nav-bar title="活动记录" :show="show" @showPopup="show=true" @hidePopup="show=false" :hideFunc="true"></edu-nav-bar>
    <div style="width: 92%;margin: 0 auto;background-color: white;padding: 5px 8px;margin: 10px auto;">
      <div style="display: flex;justify-content: center;margin: 5px auto 20px;font-size: 18px;font-weight: bold;">{{ data.activityName }}</div>
      <div class="item-desc"><div class="item-title">活动单号：</div><div class="item-note">{{ 'HD' + data.activityBuyMasterId }}</div></div>
      <div class="item-desc"><div class="item-title">购买时间：</div><div class="item-note">{{ data.buyTime }}</div></div>
      <div class="item-desc"><div class="item-title">活动费用：</div><div class="item-note">￥{{ data.totalMoney }}</div></div>
      <div class="item-desc"><div class="item-title">支付状态：</div><div class="item-note">{{ data.isPay == 1 ? '已支付' : '未支付' }}</div></div>
      <div class="item-desc"><div class="item-title">支付时间：</div><div class="item-note">{{ data.payTime }}</div></div>
      <div style="display: flex;justify-content: center;font-size: 18px;font-weight: bold;margin: 10px auto 5px;">详细信息</div>
      <template v-for="(detail, index) in data.settingdetails">
        <div  v-if="detail.itemType != 'Goods'" class="item-desc"  :key="'sd_' + index">
          <div class="item-title">{{detail.itemName}}：</div>
          <div class="item-note">{{ detail.answer }}</div>
        </div>
        <div v-else  :key="'sd_' + index" class="item-desc" style="flex-direction: column;justify-content: flex-start;align-items: flex-start;background-color: #eeeeee;padding: 5px;">
          <div class="item-title">{{detail.itemName}}：</div>
          <template v-for="(item, i) in detail.items">
            <div :key="'item_' + i" style="margin-left: 15px;width: calc(100% - 30px);">
              <div style="display: flex;width: 100%;"><div style="flex: 1;">项目{{i+1}}名称：</div><div>{{ item.itemName }}</div></div>
              <div style="display: flex;width: 100%;"><div style="flex: 1;">购买数量：</div><div>{{ item.qty }}</div></div>
              <div style="display: flex;width: 100%;"><div style="flex: 1;">费用：</div><div>￥{{ item.price }}</div></div>
            </div>
          </template>
        </div>
      </template>


    </div>
  </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar";
import ActivityApi from "@/api/ActivityApi";
export default {
  components: {
    EduNavBar,
  },
  data() {
    return {
      show: false,
      data: {}
    }
  },
  methods: {
    getData() {
      ActivityApi.getActivityBuyDetails({ activityBuyMasterId: this.$route.query.activityBuyMasterId }).then(response => {
        console.log('this.data == ', response.res)
        this.data = response.res
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style scoped>
.item-desc {
  display: flex;
  align-items: center;
  line-height: 28px;
}
.item-title {
  flex: 1;
  /*width: 100px;*/
}
</style>
